import {List, ListItem, ListItemText} from "@mui/material";
import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import NavBar from "../common/NavBar";
import {isIP} from "is-ip";

interface Domain {
    status: string,
    message: string,
    resolvedBy: string,
    records:
        {
            dns: string[],
            mx: string[],
            ns: string[],
            txt: string[],
        }
}

interface IP {
    status: string,
    message: string,
    reversDNS: string,
    IPInfo: string
}

function getDomainInfo(request: string): Promise<Domain> {

    return fetch("https://api.werisses.de/" + request)
        .then(response => response.json())
        .then(response => {return response as Domain})

}

function getIPInfo(request: string): Promise<IP> {

    return fetch("https://api.werisses.de/" + request)
        .then(response => response.json())
        .then(response => {return response as IP})

}

// for future use!
function getTinyIP(request: string): Promise<IP> {

    return fetch("https://api.werisses.de/" + request, {headers: { "ReportType": "Tiny" }})
        .then(response => response.json())
        .then(response => {return response as IP})

}

// for future use!
function getData(request: string): Promise<Domain> {

    return fetch("https://api.werisses.de/" + request)
        .then(response => response.json())
        .then(response => {return response as Domain})

}

function mapStringList(input: string[] | undefined): JSX.Element  {

    let data = input?.map((data: string) => (
        <ListItem disablePadding>
            <ListItemText>
                <a style={{fontFamily: "courier",
                    wordBreak: "break-word"}}>
                {data}
                </a>
            </ListItemText>
        </ListItem>
    ))

    return (
        <List style={{fontFamily: "courier",
            wordBreak: "break-word"}}>
            {data}
        </List>
    );

}

export default function Resolve() {

    const [domainRequest, setDomainRequest] = useState(false);
    const [loading, setLoading] = useState(true);
    const [domainData, setDomainData] = useState<Domain>();
    const [ipData, setipData] = useState<IP>({status: "", message: "", reversDNS: "", IPInfo: ""});

    let request = useLocation().pathname.substring(1)

    useEffect(() => {

        // clear out all the Data we currently have stored
        setLoading(true);

        if (!isIP(request)) {
            getDomainInfo(request).then(data => {
                setDomainRequest(true);
                setDomainData(data);
                setLoading(false);
            })
        } else {
            getIPInfo(request).then(data => {
                setDomainRequest(false);
                setipData(data);
                setLoading(false);
            })
        }

    }, [request]);

    // this page should return the data about the requested domain or ip address
    // the data is retrieved from werisses.de/api and is returned as json
    // the data is then displayed on the page

    return (
        <>
            <NavBar />
            <div style={{
                maxWidth: "1000px",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <center><p><strong>You requested Information about {request}.</strong></p></center>

                {loading && <p>Please wait while we are gathering your data!</p>}
                {(!loading && domainRequest )&&

                    <>
                        { domainData?.resolvedBy !== "" ? <p>The Data has been fetched live from <strong>{domainData?.resolvedBy}</strong> for your convenience.</p> : null }

                        <div>
                            <strong>DNS Records (A & AAAA)</strong><br />

                            {
                                domainData?.records.dns === null ? <p>No DNS Records found!</p> :

                                    domainData?.records.dns.map((data: string) => (
                                        <>
                                            <NavLink key={data} to={data}><a style={{
                                                fontFamily: "courier",
                                                wordBreak: "break-word"
                                            }}>{data}</a></NavLink>
                                            <br/>
                                        </>
                                    ))

                            }
                            <br />
                        </div>
                        <div>
                            <strong>MX Records</strong><br />

                            {
                                domainData?.records.mx === null ? <p>No MX Records found!</p> :

                                    domainData?.records.mx.map((data: string) => (
                                        <>
                                            <NavLink key={data} to={data}><a style={{
                                                fontFamily: "courier",
                                                wordBreak: "break-word"
                                            }}>{data}</a></NavLink>
                                            <br/>
                                        </>
                                    ))

                            }
                            <br />
                        </div>
                        <div>
                            <strong>Nameserver</strong><br />

                            {
                                domainData?.records.ns === null ? <p>No Nameserver found!</p> :

                                    domainData?.records.ns.map((data: string) => (
                                        <>
                                            <NavLink key={data} to={data}><a style={{
                                                fontFamily: "courier",
                                                wordBreak: "break-word"
                                            }}>{data}</a></NavLink>
                                            <br/>
                                        </>
                                    ))

                            }
                            <br />
                        </div>
                        <div>
                            <strong>TXT Records</strong><br />

                            {
                                domainData?.records.txt === null ? <p>No TXT Records found!</p> :

                                    mapStringList(domainData?.records.txt)

                            }
                            <br />
                        </div>
                    </>
                }

                {(!loading && !domainRequest && ipData.status !== "ERR") &&


                    <>
                        <div>
                            <strong>ReversDNS</strong><br />

                            {
                                ipData.reversDNS === "" ? <p>No Revers DNS Records found!</p> :

                                    <NavLink to={ipData.reversDNS}>
                                        <a>{ipData.reversDNS}</a>
                                    </NavLink>
                            }
                            <br />
                        </div>

                        <div>
                            <strong>IP Information</strong><br />

                            {
                                ipData.IPInfo === "" ? <p>No IP Infos found!</p> :

                                    <a style={{whiteSpace: "pre-line", fontFamily: "courier", wordBreak: "break-word"}}>{ipData.IPInfo}</a>
                            }
                            <br />
                        </div>

                    </>
                }

                {(!loading && !domainRequest && ipData.status === "ERR") &&
                    <>
                        <strong>There was an error with your request!</strong>
                        <p>If you believe this was an error please get in touch with me! It currently looks like, that either you request isnt correct, or WerIsses is having trouble resolving your request.
                        This can have several Reasons. If you want to help out, please get in contact with me, and tell me what you tried to get information about.</p>
                    </>
                 }

            </div>
        </>

    )
}