import React from "react";
import NavBar from "../common/NavBar";

export default function Home() {
    return (
        <>
            <NavBar />

            <div style={{
                maxWidth: "800px",
                marginLeft: "auto",
                marginRight: "auto"
            }}>
                <center><h1>Welcome to WerIsses!</h1></center>
                <p>WerIsses is a simple tool to retrieve Information about Domains and IP-Addresses.</p>
                <p>The Service is free of charge and is run by <a href={"https://jonathan-broering.de"}>me</a> as an hobby project. The API is also freely available, but currently there is no Documentation available for it.
                    The Usage is pretty simple, and you should get how it works by looking at the requests fairly easy.</p>
                <p>If you have any questions, improvements, or just questions, feel free to contact me!</p>
            </div>
        </>
    )
}