import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Resolve from "./pages/Resolve";

function App() {

  return (
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home />}/>
                  <Route path="/*" element={<Resolve />}/>
              </Routes>
          </BrowserRouter>
  );
}

export default App;
