import {alpha, AppBar, Box, InputBase, styled, Toolbar, Typography} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export default function NavBar() {
    let [searchText, setSearchText] = useState<string>("");

    const navigate = useNavigate();

    const navigateToNewRoute = (route: string) => {
        navigate(route);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{backgroundColor: "#E07C24"}}>
                <Toolbar variant="dense">

                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}

                        onClick={(event) => {
                            navigateToNewRoute("/");
                        }}
                    >
                        WerIsses Toolbox
                    </Typography>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            navigateToNewRoute(searchText);
                        }}
                    >
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event) => {
                                    setSearchText(event.target.value);
                                }}
                            />
                        </Search>
                    </form>
                </Toolbar>
            </AppBar>
        </Box>
    )

}